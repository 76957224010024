import React, { useEffect } from "react";
import Modal from "react-modal";

import { shutterbDashboardActions } from "../../../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const ReelDetailsModal = (props) => {
  useEffect(() => {
    props?.reelModalEventId && props.getReelDetails(props.reelModalEventId);
    // eslint-disable-next-line
  }, [props?.reelModalEventId]);

  return (
    <Modal
      isOpen={props.isModalOpen}
      onRequestClose={() => props.setIsModalOpen(false)}
      className={props.className}
    >
      <span className="close" onClick={() => props.setIsModalOpen(false)}>
        x
      </span>
      <>
        <div className="container-fluid main-con">
          <div className="row">
            <table>
              <thead>
                <tr>
                  <td colSpan="12">Recap Brief </td>
                </tr>
                <tr>
                  <td colSpan="12">{props?.reelModalEventId ?? 0}</td>
                </tr>
              </thead>
              <tbody>
                {props.reelDetails?.reelDetails[0]?.logoUrl && (
                  <tr>
                    <th>Logo Url:</th>
                    <td>
                      {process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY +
                        "/" +
                        props.reelDetails?.reelDetails[0]?.logoUrl}
                    </td>
                  </tr>
                )}
                {props.reelDetails?.reelDetails[0]?.musicFilePath && (
                  <tr>
                    <th>Music File Url:</th>
                    <td>
                      {process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY +
                        "/" +
                        props.reelDetails?.reelDetails[0]?.musicFilePath}
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Music:</th>
                  <td>{props.reelDetails?.reelDetails[0]?.music}</td>
                </tr>
                <tr>
                  <th>Orientation:</th>
                  <td>{props.reelDetails?.reelDetails[0]?.orientation}</td>
                </tr>
                {props.reelDetails?.reelDetails[0]?.reelType && (
                  <tr>
                    <th>Recap Type:</th>
                    <td>{props.reelDetails?.reelDetails[0]?.reelType}</td>
                  </tr>
                )}
                {/* <tr>
                  <th>What are you using this reel for?</th>
                  <td>{props.reelDetails?.reelQuestionAnswers[0]?.answer}</td>
                </tr> */}
                <tr>
                  <th>Vision for the recap:</th>
                  <td>{props.reelDetails?.reelQuestionAnswers[0]?.answer}</td>
                </tr>
                {/* <tr>
                  <th>Special moments you are hoping to feature:</th>
                  <td>{props.reelDetails?.reelQuestionAnswers[2]?.answer}</td>
                </tr>
                <tr>
                  <th>Guests they want to focus on:</th>
                  <td>{props.reelDetails?.reelQuestionAnswers[3]?.answer}</td>
                </tr>
                <tr>
                  <th>"Guests" who should not be included:</th>
                  <td>{props.reelDetails?.reelQuestionAnswers[4]?.answer}</td>
                </tr>
                {props.reelDetails?.reelQuestionAnswers[5]?.answer && (
                  <tr>
                    <th>Brand guidelines & Legal Requirements</th>
                    <td>{props.reelDetails?.reelQuestionAnswers[5]?.answer}</td>
                  </tr>
                )} */}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </Modal>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  reelDetails: state.shutterbDashboard.reelDetails,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getReelDetails: (eventId) =>
    dispatch(shutterbDashboardActions.getReelDetails(eventId)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReelDetailsModal));
