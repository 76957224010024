import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import Balancer from "react-wrap-balancer";
import Modal from "react-modal";
import { eventBookingActions } from "../../../../redux/actions/eventBooking.actions";
import {
  configurationsConstants,
  eventBookingConstants,
} from "../../../../constants";
import { toast } from "react-toastify";
import { customToast } from "../../../../shared/utility";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";

const AddReel = ({ onNext, onBack, ...props }) => {
  const { t } = useTranslation("common");
  const { isDraftEvent } = useParams();
  const isOnlyReel = localStorage.getItem("isOnlyReel");
  const [reelTypeId, setReelTypeId] = useState(0);
  const [sampleUrl, setSampleUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const { TABS, ADD_REEL_STATES, REEL_ORIENTATION_OPTIONS } =
    eventBookingConstants;
  const {
    isReelWithLogo,
    reelPriceWithLogo,
    reelQtyWithLogo,
    logo,
    reelQuestion1,
    music,
    orientation,
    musicFile,
  } = props.addReel;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isReelWithLogo && Number(reelQtyWithLogo) === 0) {
      customToast("Recap Quantity Must be Greater than 0", toast.TYPE.ERROR);
      return;
    }
    window.dataLayer.push({
      event: "site_action",
      action: "recap_reel_radio",
    });
    props.setReelValues({
      name: ADD_REEL_STATES.IS_REEL_SUBMITTED,
      value: true,
    });
    // setting the active tab
    onNext();
  };
  const handleSkip = () => {
    // props.setReelValues({
    //   name: ADD_REEL_STATES.IS_REEL_WITH_LOGO,
    //   value: false,
    // });
    props.setReelValues({
      name: ADD_REEL_STATES.IS_REEL_SUBMITTED,
      value: false,
    });

    // setting the active tab
    onNext();
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  const handleLogoUpload = async (img) => {
    if (
      img.target.files[0].type.includes("png") ||
      img.target.files[0].type.includes("postscript") ||
      img.target.files[0].type.includes("ai") ||
      img.target.files[0].type.includes("eps")
    ) {
      // let logoPreview = URL.createObjectURL(img.target.files[0]);
      let logoPreview = { name: img.target.files[0].name };
      props.setReelValues({
        name: ADD_REEL_STATES.LOGO,
        value: {
          raw: await toBase64(img.target.files[0]),
          preview: logoPreview,
        },
      });
    } else {
      customToast(t("invalid_image_type"), toast.TYPE.ERROR);
      return;
    }
  };

  const handleMusicUpload = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const allowedFileTypes = ["audio/mp3", "audio/mpeg", "audio/wav"];
      const isValidFileType = allowedFileTypes.includes(selectedFile.type);
      const maxSizeInBytes = 11 * 1024 * 1024; // 11MB

      if (isValidFileType) {
        if (selectedFile.size <= maxSizeInBytes) {
          const fileName = selectedFile.name;
          const musicFileData = new FormData();

          musicFileData.append("fileName", fileName);
          musicFileData.append("musicFile", selectedFile);

          props.uploadMusicFile(musicFileData, fileName);
        } else {
          customToast("File size exceeds 10MB limit", toast.TYPE.ERROR);
        }
      } else {
        customToast("Invalid Music File Type", toast.TYPE.ERROR);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.getReelPrice();
    props.getReelTypes();
    localStorage.setItem("activeTab", TABS.ADD_REEL);
    props.setReelValues({
      name: ADD_REEL_STATES.IS_REEL_WITH_LOGO,
      value: true,
    });
    // eslint-disable-next-line
  }, []);

  const onReSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onReSize);

    return () => {
      window.removeEventListener("resize", onReSize);
    };
  }, []);

  return (
    <div className="container mt-5 event-booking-add-reel">
      <div className="row w-100 text-center mb-3 mt-3">
        <div className="col-md-12 p-0">
          <h1>
            <strong>recap brief</strong>
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-10 m-auto">
          {/* skip reel row */}
          <div className="">
            <h3 className="text-center">Would you like an event recap?</h3>
            <p className="text-center">
              Our shutterb editors are available to turn your shutterb captured
              short video clips into an edited video that will bring to life the
              vibe of your event to share with guests and on social.
            </p>
            {/* skip booking btn */}
            <button
              type="button"
              className="btn-simple m-auto addToCart"
              onClick={handleSkip}
              disabled={isOnlyReel}
            >
              SKIP
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            {/* reel with logo */}
            <div className="d-flex justify-content-center align-items-center flex-wrap pb-3 mt-4">
              <div>
                <input
                  type="radio"
                  id="reelTypeWithLogo"
                  name="reelType"
                  style={{ display: "none" }}
                  checked={true}
                  required
                  onChange={(e) => {
                    props.setReelValues({
                      name: ADD_REEL_STATES.IS_REEL_WITH_LOGO,
                      value: true,
                    });
                    props.setReelValues({
                      name: ADD_REEL_STATES.IS_REEL_WITHOUT_LOGO,
                      value: false,
                    });
                  }}
                />
                {/* <label className="ml-1" htmlFor="reelTypeWithLogo">
                  Up to 1 minute event video recap
                </label> */}
              </div>
              {/* col-sm-5 ml-sm-auto */}
              <div className="d-flex justify-content-center align-items-center">
                <p className="ml-1" htmlFor="reelTypeWithLogo">
                  Up to 1 minute event video recap
                </p>
              </div>
              <div className="row w-100 d-flex justify-content-center align-items-center">
                <select
                  name="reelQtyWithLogo"
                  id="reelQtyWithLogo"
                  className={`minimal`}
                  style={{ width: "25%" }}
                  value={reelQtyWithLogo}
                  onChange={(e) => {
                    props.setReelValues({
                      name: ADD_REEL_STATES.REEL_QTY_WITH_LOGO,
                      value: e.target.value,
                    });
                  }}
                >
                  {eventBookingConstants.REEL_QTY_OPTIONS.map((option, key) => {
                    if (option === reelQtyWithLogo) {
                      return (
                        <option key={key} value={option} selected>
                          {option}
                        </option>
                      );
                    } else {
                      return (
                        <option key={key} value={option}>
                          {option}
                        </option>
                      );
                    }
                  })}
                </select>
                <span className="price-per-reel">${reelPriceWithLogo}*</span>
              </div>
            </div>

            <div className="m-5">
              <h3 className="text-center">
                <Balancer>
                  price includes one round of minor revisions.
                </Balancer>
              </h3>
              <p className="text-center">
                <Balancer>
                  includes 1 music change, removing or replacing up to 3 clips.
                  feedback beyond may incur incremental costs
                </Balancer>
              </p>
            </div>
            <hr />
            <div>
              <div>
                <h5>How would you describe your vision for your recap(z)?</h5>
                <p>
                  What is the story you want to tell with this recap? What is
                  the vibe you're going for? If you have more than 1 let us know
                  the vision for each recap!
                </p>
                <textarea
                  name="question1"
                  id="question1"
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  required
                  cols="30"
                  rows="3"
                  defaultValue={reelQuestion1}
                  onChange={(e) => {
                    props.setReelValues({
                      name: ADD_REEL_STATES.REEL_QUESTION_ONE,
                      value: e.target.value,
                    });
                  }}
                ></textarea>
              </div>

              <div className="row w-100 mt-3 mb-3 p-0 ml-0">
                <h5 className="mb-4">
                  what vibe best describes what you’re looking for in this recap
                  (select one):
                </h5>
                {props.reelTypes?.map((item) => {
                  return (
                    <div
                      className="col-xl-4 col-lg-4 col-md-6 col-sm-12 
                    reel-type-div px-lg-2 px-md-2 px-xl-2 position-relative"
                    >
                      <img
                        src={
                          process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY +
                          "/" +
                          item.featureFileUrl
                        }
                        alt="reel type"
                        className={`${
                          reelTypeId === item.id ||
                          props.addReel.reelType === item.name
                            ? "reel-type w-100 reel-type-img"
                            : "w-100 reel-type-img"
                        }`}
                        onClick={() => {
                          props.setReelValues({
                            name: ADD_REEL_STATES.REEL_TYPE,
                            value: item.name,
                          });
                          setReelTypeId(item.id);
                        }}
                      />
                      <div
                      // style={{ marginTop: "23%" }}
                      >
                        <span className="text-light mb-1 item-title">
                          {item.name}
                        </span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          className={`btn ${
                            screenSize < 1199 ? "addTocart" : "sample-text "
                          } my-4`}
                          onClick={(e) => {
                            e.preventDefault();
                            setSampleUrl(
                              process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY +
                                "/" +
                                item.sampleFileUrl
                            );
                            setIsModalOpen(true);
                          }}
                        >
                          See Sample
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="container-fluid p-0">
              <div className="row w-100 ml-0">
                <div className="col-md-4 p-0">
                  <h5>music:</h5>
                </div>
                <div className="col-md-8 p-0">
                  <input
                    type="text"
                    name="music"
                    id="music"
                    maxLength={40}
                    required
                    placeholder="Preferred Genre"
                    value={music}
                    className="w-100"
                    onChange={(e) => {
                      props.setReelValues({
                        name: ADD_REEL_STATES.MUSIC,
                        value: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row w-100 ml-0">
                <div className="col-md-4 p-0"></div>
                <div className="col-md-8 p-0">
                  {/* upload music file */}

                  <div>
                    <p>
                      <strong>
                        if you have your own licensed music that you have rights
                        to use upload here:
                      </strong>
                    </p>
                    <div className="logo-con">
                      <label htmlFor="musicFile" className="logo">
                        {musicFile?.preview ? (
                          <>
                            <i className="fas fa-check-square text-success"></i>
                            <p className="p-0 m-0">{musicFile?.preview}</p>
                          </>
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m14-7l-5-5l-5 5m5-5v12"
                              />
                            </svg>
                            upload music
                          </>
                        )}
                      </label>
                      <input
                        type="file"
                        name="uploadmusic"
                        title="uploadmusic"
                        id="musicFile"
                        onChange={handleMusicUpload}
                        accept=".mp3, .wav"
                        hidden
                      />
                    </div>
                    <label className="p-0 m-0">
                      <p className="text-center">mp3 and wav files accepted.</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row w-100 ml-0">
                <div className="col-md-4 p-0">
                  <h5 className="m-0 mr-2 mr-md-0">orientation:</h5>
                </div>
                <div className="col-md-8 p-0">
                  <select
                    name="orientation"
                    id="orientation"
                    value={orientation}
                    required
                    placeholder="Landscape or portrait"
                    className="minimal w-100"
                    onChange={(e) => {
                      props.setReelValues({
                        name: ADD_REEL_STATES.ORIENTATION,
                        value: e.target.value,
                      });
                    }}
                  >
                    {REEL_ORIENTATION_OPTIONS.map((option) => (
                      <option value={option} id="option" key={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row w-100 mt-4 ml-0">
              <div className="col-md-12 p-0">
                {/* upload music file */}

                <div>
                  <p>
                    <strong>
                      if you’d like your logo included please upload here
                    </strong>
                  </p>
                  <div className="logo-con">
                    <label htmlFor="logo" className="logo">
                      {logo?.preview ? (
                        <>
                          <i className="fas fa-check-square text-success"></i>
                          <p className="p-0 m-0">{logo.preview.name}</p>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m14-7l-5-5l-5 5m5-5v12"
                            />
                          </svg>
                          Add logo
                        </>
                      )}
                    </label>
                    <input
                      type="file"
                      name="logo"
                      title="logo"
                      id="logo"
                      accept=".png,.ai,.eps"
                      onChange={handleLogoUpload}
                      hidden
                    />
                  </div>
                  <label className="p-0 m-0">
                    <p className="text-center">
                      png. ai. eps. files accepted. must be on a transparent
                      background. Preference is white or knocked out version of
                      logos to live over picture.
                    </p>
                  </label>
                </div>
              </div>
            </div>
            {/* subtotal row */}
            <div className="row mx-0 mt-3">
              <div className="col-sm-8 p-0">
                <h3>Recap Subtotal</h3>
              </div>
              <div className="col-sm-4 p-0 m-0">
                <input
                  className="sub-total w-100 px-0"
                  type="text"
                  readOnly
                  disabled
                  value={
                    "$" +
                    String(
                      isReelWithLogo === true
                        ? Number(reelQtyWithLogo * reelPriceWithLogo)
                        : 0
                    ) +
                    "*"
                  }
                />
              </div>
            </div>

            {/* continue booking btn */}
            <div className="text-center show_continue_booking_shutterb h-auto d-flex justify-content-center align-items-center">
              <button type="submit" className="btn addToCart">
                Continue Booking
              </button>
            </div>
            <label htmlFor="">
              *Recap(z) are subject to our 5% service fee and 13% HST
            </label>
          </form>
        </div>
      </div>

      {/* back btn */}

      {Number(isDraftEvent) !== eventBookingConstants.TABS.CART && (
        <div className="row pt-4 ml-2">
          <div className="event-booking-form-btn col-sm-10 m-auto px-0">
            <button
              type="submit"
              className="back-btn"
              onClick={onBack}
              disabled={isOnlyReel}
            >
              Back
            </button>
          </div>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={setIsModalOpen}
        contentLabel="Image Modal"
        className="image-modal modal-border-color"
      >
        <div className="modal-content">
          <LazyLoad height={"fit-content"} threshold={0.95}>
            <video
              src={sampleUrl}
              autoPlay={true}
              muted={true}
              preload="auto"
              className={"col-12 px-0 h-100 reel-file-Content"}
              controls={true}
            ></video>
          </LazyLoad>
          <button onClick={() => setIsModalOpen(false)} className="modal-close">
            <img
              src="/images/simpleCrossWhite.svg"
              alt="crossIcon"
              className="modal-close-img"
            />
          </button>
        </div>
      </Modal>
    </div>
  );
};
// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  addReel: state.eventBooking.addReel,
  reelTypes: state.eventBooking.reelTypes,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setReelValues: (dataObj) =>
    dispatch({ type: eventBookingConstants.SET_REEL_VALUES, data: dataObj }),
  getReelPrice: () => dispatch(eventBookingActions.getReelPrice()),
  getReelTypes: () => dispatch(eventBookingActions.getReelTypes()),
  uploadMusicFile: (formData, fileName) =>
    dispatch(eventBookingActions.uploadMusicFile(formData, fileName)),
});
// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddReel));
