import React, { useState, useEffect } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { customToast } from "../../../shared/utility";
import Modal from "../../Views/Login/Modal";
import { NavigateToPath } from "../../../system";
import { loginActions, messageBoardActions } from "../../../redux/actions";
import {
  customerConstants,
  eventBookingConstants,
  shutterbDashboardConstants,
  messageBoardConstants,
} from "../../../constants";
import MessageBoardModal from "../../../shared/components/MessageBoard/Modal/MessageBoardModal";
import { startConnection } from "../../../system/signalRHOC";
import { useDispatch } from "react-redux";
import { custom } from "../../../redux/actions/utilities";
import avatar from "../../../shared/img/avatar.jpg";

const Topbar = (props) => {
  /* eslint-disable */
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const existingData = localStorage.getItem("dataToSend");
  const parsedData = JSON.parse(existingData);
  const [savedOrder, setSavedOrder] = useState(
    parsedData?.Event?.Order?.orderItems
  );
  const notNewEvent = localStorage.getItem(customerConstants.NOT_NEW_EVENT);
  const [isNavCollapsed] = useState(true);

  useEffect(() => {
    if (props.authentication || props.isShutterbAuthenticated) {
      const authUserId = props.authentication
        ? props.auth.userId
        : props.sbAuth.sbUserId;
      const authUserType = props.auth.userType;
      props.getUserNotificationCount(authUserId, authUserType);
    }
    const existingData = localStorage.getItem("dataToSend");
    const parsedData = JSON.parse(existingData);
    if (
      (savedOrder && savedOrder.length === 0) ||
      (parsedData?.Event?.Order?.orderItems &&
        parsedData?.Event?.Order?.orderItems !== existingData)
    ) {
      setSavedOrder(parsedData?.Event?.Order?.orderItems);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.authentication || props.isShutterbAuthenticated) {
      const setupConnection = async () => {
        try {
          // Establish the connection
          const newConnection = await startConnection(
            props.userId,
            0,
            null,
            props.authentication
              ? customerConstants.HOST
              : shutterbDashboardConstants.SHUTTERB
          );
          newConnection.on("ReceiveNotification", (message) => {
            newConnection.invoke(
              "NotificationsCount",
              message.receiverId,
              message.userType
            );
          });

          // Dispatch an action to update Redux state with the received message
          // Listen for notifications count
          newConnection.on("ReceiveNotificationsCount", (notificationCount) => {
            dispatch(
              custom(
                notificationCount,
                messageBoardConstants.GET_NOTIFICATION_COUNT
              )
            );
          });
        } catch (error) {
          console.error("Error setting up connection:", error);
        }
      };
      setupConnection();
    }
  }, [dispatch]);

  const updateCart = () => {
    const existingData = localStorage.getItem("dataToSend");
    const parsedData = JSON.parse(existingData);
    setSavedOrder(parsedData?.Event?.Order?.orderItems);
  };

  const handleBookNowClick = (e) => {
    window.dataLayer.push({
      event: "site_action",
      action: "book_now_header",
    });
    const existingData = localStorage.getItem("dataToSend");
    const parsedData = JSON.parse(existingData);
    if (
      !notNewEvent &&
      !(
        localStorage.getItem("activeTab") == eventBookingConstants.TABS.CART &&
        !parsedData.Event.eventDate
      )
    ) {
      if (parsedData && parsedData?.Event?.Order?.orderItems?.length > 0) {
        localStorage.setItem("activeTab", eventBookingConstants.TABS.CART);
        NavigateToPath(history, "/event_booking", "/event_booking", 2);
      } else if (
        JSON.parse(localStorage.getItem("activeTab")) ===
          eventBookingConstants.TABS.CART &&
        parsedData?.Event?.Order?.orderItems.length === 0
      ) {
        localStorage.setItem(
          "activeTab",
          eventBookingConstants.TABS.SELECT_SHUTTERB
        );
      }
      NavigateToPath(history, "/event_booking", "/event_booking", 2);
    } else {
      props.emptyEventBookingRed();

      const tempToken = localStorage.getItem(process.env.REACT_APP_PAYLOAD);
      localStorage.clear();
      localStorage.setItem(process.env.REACT_APP_PAYLOAD, tempToken);
      localStorage.setItem("activeTab", eventBookingConstants.TABS.EVENT_SETUP);
      window.location.replace("/event_booking");
    }
  };

  const handleNotificationClick = (values) => {
    // e.preventDefault();
    // Toggle the state to open/close the notification list
    setIsNotificationOpen(false);
    closeNotificationPanel();
    if (props.authentication) {
      props.setCustomerChatStart({ currentTabId: values.sbUserId });
      history.push(
        `/CustomerDashboardTab/MyEvents/${values.eventId}/${customerConstants.MESSAGE_BOARD}`
      );
      // window.location.reload();
    }
    if (props.isShutterbAuthenticated) {
      setIsMessageModalOpen(true);
    }
    props.bulkUpdateMessageStatus(
      values.eventId,
      values.sbUserId,
      props.auth.userId,
      props.auth.userType
    );
    props.getMessages(values.eventId, values.sbUserId);
    props.setFormState({
      ...props.formState,
      eventId: values.eventId,
      sbUserId: values.sbUserId,
      userId: values.userId,
      profileImageUrl: values.profileImageUrl,
      name: props.isShutterbAuthenticated
        ? values.hostName
        : values.shutterbName,
      senderId: props.isShutterbAuthenticated ? values.sbUserId : values.userId,
      receiverId: props.isShutterbAuthenticated
        ? values.userId
        : values.sbUserId,
      sender: props.authentication
        ? customerConstants.HOST
        : shutterbDashboardConstants.SHUTTERB,
    });
  };
  const openNotificationPanel = () => {
    document.getElementById("notificationSidebar").style.width = "330px";
    // document.getElementById("notification-main").style.marginRight = "250px";
    document.getElementById("notification-main").style.visibility = "visible";
  };

  const closeNotificationPanel = () => {
    document.getElementById("notificationSidebar").style.width = "0";
    document.getElementById("notification-main").style.marginRight = "0";
    document.getElementById("notification-main").style.visibility = "hidden";
  };
  return (
    <>
      <header
        id="topbar-header"
        className={`border-bottom border-1x ${isMenuOpen ? "pb-0" : ""}`}
      >
        <div className="main-header p-0">
          <nav
            className={`navbar navbar-expand-lg pl-0 pr-0 ${
              isMenuOpen ? "pb-0" : ""
            }`}
          >
            <div className="pl-md-5 pl-lg-5 pl-3">
              <Link className="navbar-brand" to="#">
                <img
                  src="/images/logo.png"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_MARKETING_SITE_BASE_URL}`;
                  }}
                  alt=""
                />
              </Link>
            </div>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <span className="navbar-toggler-icon">
                <i className={`clr-blue fas fa-bars`}></i>
              </span>
            </button>

            <div
              className={`collapse navbar-collapse  pr-md-5 pr-lg-5  ${
                isMenuOpen ? "small-device-menu-items" : ""
              }`}
              aria-expanded={!isNavCollapsed}
              id="navbarResponsive"
            >
              <ul className="navbar-nav help-ul ml-auto font-12">
                {props.authentication || props.isShutterbAuthenticated ? (
                  // logout btn
                  <li className="nav-item pr-4">
                    <a
                      className="nav-link text-dark font-weight-bold font-12"
                      href="/"
                      data-toggle="modal"
                      data-target="#exampleModalLong"
                      onClick={() => {
                        props.onLogoutClick();
                      }}
                    >
                      Logout
                    </a>
                  </li>
                ) : (
                  // login btn
                  <li className="nav-item pr-4">
                    <a
                      className="nav-link text-dark font-weight-bold font-12"
                      href="/"
                      data-toggle="collapse"
                      data-target="#navbarResponsive"
                      onClick={() => {
                        NavigateToPath(history, "/Login");
                      }}
                    >
                      LOGIN
                    </a>
                  </li>
                )}

                <li className="nav-item pr-4">
                  <a
                    className="text-dark font-weight-bold font-12"
                    href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/our-story/`}
                  >
                    Our Story
                  </a>
                </li>

                <li className="nav-item pr-4">
                  <a
                    className="text-dark font-weight-bold font-12"
                    href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/faq/`}
                  >
                    FAQ
                  </a>
                </li>

                <li className="nav-item pr-2">
                  <a
                    href="#"
                    className="text-dark font-weight-bold font-12"
                    data-toggle="collapse" // collapse navar onClick
                    data-target="#navbarResponsive"
                    onClick={() => NavigateToPath(history, "/be-a-shutterb")}
                  >
                    Be a ShutterB
                  </a>
                </li>

                <li className="nav-item pr-4">
                  <a
                    className="text-dark font-weight-bold font-12"
                    href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/business/`}
                  >
                    Business
                  </a>
                </li>
                {/* cart icon for customer */}
                {props.authentication ? (
                  <li
                    className="nav-item pr-2 d-flex font-12"
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                  >
                    {window.location.pathname.includes(
                      customerConstants.DRAFT_EVENT_TOP_BAR_CHECK
                    ) ? (
                      <a className="text-dark font-weight-bold font-12">
                        <li>
                          <i class="fas fa-shopping-cart"></i>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 256 256"
                            style={{ height: "24px", width: "24px" }}
                          >
                            <path
                              fill="black"
                              d="M219.07 61.44A4 4 0 0 0 216 60H51.34l-5.48-30.15A12 12 0 0 0 34.05 20H16a4 4 0 0 0 0 8h18a4 4 0 0 1 4 3.28l25.5 140.3a20 20 0 0 0 7.5 12.27a24 24 0 1 0 30.87 4.15h60.26a24 24 0 1 0 17.87-8H83.17a12 12 0 0 1-11.8-9.85l-4-22.15H188.1a20 20 0 0 0 19.68-16.42l12.16-66.86a4 4 0 0 0-.87-3.28ZM100 204a16 16 0 1 1-16-16a16 16 0 0 1 16 16Zm96 0a16 16 0 1 1-16-16a16 16 0 0 1 16 16Zm3.91-73.85A12 12 0 0 1 188.1 140H65.88L52.79 68h158.42Z"
                            />
                          </svg> */}
                        </li>
                      </a>
                    ) : !notNewEvent ? (
                      <a
                        className="text-dark font-weight-bold font-12"
                        onClick={() => {
                          updateCart();
                          if (
                            localStorage.hasOwnProperty("dataToSend") &&
                            JSON.parse(localStorage.getItem("dataToSend"))
                              ?.Event?.Order?.orderItems?.length > 0
                          ) {
                            localStorage.setItem(
                              "activeTab",
                              eventBookingConstants.TABS.CART
                            );
                            NavigateToPath(
                              history,
                              "/event_booking",
                              "/event_booking",
                              2
                            );
                          } else {
                            if (JSON.parse(localStorage.getItem("activeTab"))) {
                              if (
                                JSON.parse(
                                  localStorage.getItem("activeTab")
                                ) === eventBookingConstants.TABS.CART &&
                                savedOrder?.length === 0
                              ) {
                                localStorage.setItem(
                                  "activeTab",
                                  eventBookingConstants.TABS.SELECT_SHUTTERB
                                );
                              }
                            }
                            customToast(t("cart_is_empty"), toast.TYPE.ERROR);
                          }
                        }}
                      >
                        <li>
                          <i class="fas fa-shopping-cart"></i>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 256 256"
                            // style={{ height: "24px", width: "24px" }}
                          >
                            <path
                              fill="black"
                              d="M219.07 61.44A4 4 0 0 0 216 60H51.34l-5.48-30.15A12 12 0 0 0 34.05 20H16a4 4 0 0 0 0 8h18a4 4 0 0 1 4 3.28l25.5 140.3a20 20 0 0 0 7.5 12.27a24 24 0 1 0 30.87 4.15h60.26a24 24 0 1 0 17.87-8H83.17a12 12 0 0 1-11.8-9.85l-4-22.15H188.1a20 20 0 0 0 19.68-16.42l12.16-66.86a4 4 0 0 0-.87-3.28ZM100 204a16 16 0 1 1-16-16a16 16 0 0 1 16 16Zm96 0a16 16 0 1 1-16-16a16 16 0 0 1 16 16Zm3.91-73.85A12 12 0 0 1 188.1 140H65.88L52.79 68h158.42Z"
                            />
                          </svg> */}
                        </li>
                      </a>
                    ) : (
                      <a
                        className="text-dark font-12"
                        onClick={() => {
                          if (props.customerDashboardCart.length > 0) {
                            localStorage.setItem(
                              "activeTab",
                              eventBookingConstants.TABS.CART
                            );
                            NavigateToPath(
                              history,
                              "/event_booking",
                              "/event_booking",
                              2
                            );
                          } else {
                            localStorage.removeItem("activeTab");
                            customToast(t("cart_is_empty"), toast.TYPE.ERROR);
                          }
                        }}
                      >
                        <li>
                          <i class="fas fa-shopping-cart"></i>
                        </li>
                        {/* <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 256 256"
                            style={{ height: "24px", width: "24px" }}
                          >
                            <path
                              fill="black"
                              d="M219.07 61.44A4 4 0 0 0 216 60H51.34l-5.48-30.15A12 12 0 0 0 34.05 20H16a4 4 0 0 0 0 8h18a4 4 0 0 1 4 3.28l25.5 140.3a20 20 0 0 0 7.5 12.27a24 24 0 1 0 30.87 4.15h60.26a24 24 0 1 0 17.87-8H83.17a12 12 0 0 1-11.8-9.85l-4-22.15H188.1a20 20 0 0 0 19.68-16.42l12.16-66.86a4 4 0 0 0-.87-3.28ZM100 204a16 16 0 1 1-16-16a16 16 0 0 1 16 16Zm96 0a16 16 0 1 1-16-16a16 16 0 0 1 16 16Zm3.91-73.85A12 12 0 0 1 188.1 140H65.88L52.79 68h158.42Z"
                            />
                          </svg>
                        </li> */}
                      </a>
                    )}
                  </li>
                ) : (
                  ""
                )}

                {/* dashboard icon for sb and customer */}
                {props.authentication || props.isShutterbAuthenticated ? (
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                  >
                    <a
                      href="#"
                      className="text-dark font-weight-bold font-12 m-0"
                      onClick={() => {
                        if (props.isShutterbAuthenticated) {
                          window.location.href = `${process.env.REACT_APP_HOST_BASE_URL}/Shutterbdashboard`;
                          /*NavigateToPathWithGo(
                            history,
                            "/Shutterbdashboard",
                            "/Shutterbdashboard",
                            2
                          );*/
                        } else {
                          window.location.href = `${process.env.REACT_APP_HOST_BASE_URL}/CustomerDashboard`;
                          /*NavigateToPathWithGo(
                            history,
                            "/CustomerDashboard",
                            "/CustomerDashboard",
                            2
                          );*/
                        }
                      }}
                    >
                      <i className="fas fa-user text-dark mr-3"></i>
                    </a>
                    {/*<Link
                      to={
                        props.isShutterbAuthenticated
                          ? "/Shutterbdashboard"
                          : "/CustomerDashboard"
                      }
                    >
                      <i className="fas fa-user white-icon mr-3"></i>
                    </Link>*/}
                  </li>
                ) : (
                  ""
                )}
                {props.authentication || props.isShutterbAuthenticated ? (
                  <li className="nav-item pr-4">
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isNotificationOpen) {
                          props.getNotifications(
                            props.auth.userId,
                            props.auth.userType
                          );
                          props.getUserNotificationCount(
                            props.auth.userId,
                            props.auth.userType
                          );
                        }
                        // setIsNotificationOpen(!isNotificationOpen);
                        openNotificationPanel();
                      }}
                      className="clr-white font-12"
                    >
                      <i className="fas fa-bell text-dark"></i>
                      {props.notificationCount > 0 ? (
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-light">
                          {props.notificationCount}
                        </span>
                      ) : (
                        ""
                      )}
                    </a>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <ul className={`contact_search ${isMenuOpen ? "pt-2" : ""}`}>
                <li
                  className="contact"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                >
                  <Link
                    to="/event_booking"
                    onClick={handleBookNowClick}
                    className={
                      props.isShutterbAuthenticated
                        ? "contact-us-disabled d-none"
                        : "contact-us"
                    }
                  >
                    BOOK NOW
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <input type="hidden" value="/" id="site_url" />
        </div>
      </header>
      {isModalOpen && <Modal setIsModalOpen={setIsModalOpen} />}
      <div id="notification-main">
        <div
          id="notificationSidebar"
          className="notification-sidebar notification-zindex"
        >
          <div className="closebtn">
            <p className="notification-heading d-flex justify-content-start align-items-center">
              <b>Notifications</b>
            </p>
            <span
              href="javascript:void(0)"
              className="d-flex justify-content-end"
              onClick={() => closeNotificationPanel()}
              style={{ cursor: "pointer" }}
            >
              &times;
            </span>
          </div>
          {props.notifications?.length > 0 ? (
            props.notifications.map((item) => {
              return (
                <a
                  className="border-bottom text-dark"
                  style={{ cursor: "text" }}
                  // onClick={() => {
                  //   handleNotificationClick({
                  //     eventId: item.eventId,
                  //     sbUserId: item.sbUserId,
                  //     userId: item.userId,
                  //     hostName: item.hostName,
                  //     shutterbName: item.shutterbName,
                  //     profileImageUrl: item.profileImageUrl,
                  //   });
                  // }}
                >
                  <div className="row m-0 p-0">
                    <div className="col-2 m-0 p-0">
                      <img
                        className="rounded-circle notification-img"
                        src={
                          props.authentication && item.profileImageUrl
                            ? process.env.REACT_APP_S3_BASE_URL_PORTFOLIO +
                              "/" +
                              item.profileImageUrl
                            : avatar
                        }
                      />
                    </div>
                    <div className="col m-0 p-0">
                      <div className="m-0 text-break text-dark sidebar-notifications">
                        <b>
                          {props.authentication
                            ? item.shutterbName
                            : item.hostName}
                        </b>
                        <p className="mb-0">
                          Sent you a message against {item.eventName}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })
          ) : (
            <p className="m-0 text-dark empty-notification-panel">
              No notifications to view
            </p>
          )}
        </div>
      </div>
      <MessageBoardModal
        isModalOpen={isMessageModalOpen}
        setIsModalOpen={setIsMessageModalOpen}
        currentUser={props.authentication ? props.userId : props.sbUserId}
        className="message-board-modal"
      />
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  authentication: state.authentication.isAuthenticated,
  isShutterbAuthenticated: state.shutterbAuthentication.isShutterbAuthenticated,
  userId: state.authentication.userId,
  sbUserId: state.shutterbAuthentication.sbUserId,
  customerDashboardCart: state.customerDashboard.customerDashboardCart,
  auth: state.authentication,
  sbAuth: state.shutterbAuthentication,
  notifications: state.messageBoard.notifications,
  notificationCount: state.messageBoard.notificationCount,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  onLogoutClick: () => dispatch(loginActions.logout()),
  emptyEventBookingRed: () => {
    dispatch({ data: {}, type: eventBookingConstants.SET_FORM_STATE });
    dispatch({ data: [], type: eventBookingConstants.SELECTED_SHUTTER_BS });
    dispatch({ data: false, type: eventBookingConstants.SET_CART_TAB });
    dispatch({
      data: [],
      type: customerConstants.UPDATE_CUSTOMER_DASHBOARD_CART,
    });
  },
  getNotifications: (id, type) =>
    dispatch(messageBoardActions.getNotifications(id, type)),
  getMessages: (eventId, sbUserId) =>
    dispatch(messageBoardActions.getMessages(eventId, sbUserId)),
  setFormState: (data) => dispatch(messageBoardActions.setFormState(data)),
  bulkUpdateMessageStatus: (eventId, sbUserId, userId, userType) =>
    dispatch(
      messageBoardActions.bulkUpdateMessageStatus(
        eventId,
        sbUserId,
        userId,
        userType
      )
    ),
  getUserNotificationCount: (id, type) =>
    dispatch(messageBoardActions.getUserNotificationCount(id, type)),
  setCustomerChatStart: (data) =>
    dispatch(messageBoardActions.setCustomerChatStart(data)),
});

// mapping action and store the function via props
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar));
